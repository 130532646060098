<template>
  <div>
    <div class="container text-center my-5">
      <div class="jumbotron-fluid placeholder-glow bg-light p-5">
        <!-- Go Back -->
        <router-link
          class="btn btn-primary spofo_pro_button"
          style="max-width: 250px; margin: 0px 0px 15px; padding: 8px; font-size: 15px"
          :to="{ name: 'home' }"
        >
          <i class="bi bi-arrow-left" style="font-size: 1.2em; margin-right: 5px"></i>
          <span>GAME LIST</span>
        </router-link>

        <!-- JSON download button -->
        <a
          role="button"
          target="_blank"
          :href="`https://xpand2.sportsforecaster.com/api/forecasts/hockey/schedule/${this.$route.params.key}`"
          ><img :src="require('../assets/json-file.svg')" style="max-width: 25px" />
        </a>

        <!-- Date -->
        <div class="row justify-content-center" v-if="gameData.schedule">
          <div class="col-3">
            <!-- <h4 class="mt-3 grey-bg">{{ (new Date(gameData["date"]+'T'+gameData["time"])).toString().slice(0,21) }}</h4> -->
            <h4 class="mt-3 grey-bg">{{ getEventDate(new Date(gameData.schedule.date+'T'+gameData.schedule.time)) }}</h4>
          </div>
        </div>

        <!-- Teams -->
        <div class="row">
          <div class="col-lg-5 col-md-12">
            <div class="row justify-content-center">
              <div class="circle-logo" :class="{'placeholder':loading}">
                <img
                  :src="`https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${awayTeam.team_key}.png`"
                  v-if="awayTeam.team_key"
                />
              </div>
            </div>
            <div class="row my-3">
              <h3 :class="{'placeholder w-50 m-auto':loading}">{{ awayTeam.name }}</h3>
            </div>
            <div class="row">
              <h5 class="d-flex flex-column">
                <span class="text-muted mb-2" :class="{'placeholder m-auto':loading}">Forecast</span>
                <span class="forecast-number" :class="{'placeholder m-auto':loading}">
                  {{ round2decimals(gameData.away_guessing_score) }}
                </span>
              </h5>
            </div>
          </div>
          <div class="col-lg-2 col-md-12" style="margin: auto">
            <h3 class="mt-4"><div class="atsign" style="font-size: 23px">@</div></h3>
          </div>
          <div class="col-lg-5 col-md-12">
            <div class="row justify-content-center">
              <div class="circle-logo" :class="{'placeholder':loading}">
                <img
                  :src="`https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${homeTeam.team_key}.png`"
                  v-if="awayTeam.team_key"
                />
              </div>
            </div>
            <div class="row my-3">
              <h3 :class="{'placeholder w-50 m-auto':loading}">{{ homeTeam.name }}</h3>
            </div>
            <div class="row">
              <h5 class="d-flex flex-column">
                <span class="text-muted mb-2" :class="{'placeholder m-auto':loading}">Forecast</span>
                <span class="forecast-number" :class="{'placeholder m-auto':loading}">
                  {{ round2decimals(gameData.home_guessing_score) }}
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>

      <!-- Stats tables -->
      <div class="row">
        <!-- Away team -->
        <div class="col-lg-6 col-md-12 my-3">
          <PlayerTable
            :players="awayPlayers"
            :awayTeam="awayTeam"
            :homeTeam="homeTeam"
            :teamTotal="gameData.away_guessing_score"
          ></PlayerTable>
        </div>
        <!-- Home team -->
        <div class="col-lg-6 col-md-12 my-3">
          <PlayerTable
            :players="homePlayers"
            :awayTeam="awayTeam"
            :homeTeam="homeTeam"
            :teamTotal="gameData.home_guessing_score"
          ></PlayerTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PlayerTable from "@/components/PlayerTable";
/*import TotalGuessScore from '@/components/TotalGuessScore' */

export default {
  name: "PlayerList",
  components: {
    PlayerTable,
  },

  created() {
    document.title = "Details - Game Forecast Prototype";
  },

  data() {
    return {
      gameData: {
        "away-team": {},
        "home-team": {},
      },
      awayTeam: {},
      homeTeam: {},
      awayPlayers: [],
      homePlayers: [],
      loading: false
    };
  },

  mounted() {
    this.getGame();
  },

  methods: {
    getGame() {
      this.loading = true
      axios
        .get(
          `https://xpand2.sportsforecaster.com/api/forecasts/hockey/schedule/${this.$route.params.key}`
        )
        .then((r) => {
          this.gameData = r.data.data;
          this.homeTeam = this.gameData.schedule.home_team;
          this.awayTeam = this.gameData.schedule.away_team;
          this.homePlayers = this.homeTeam.players;
          this.awayPlayers = this.awayTeam.players;
          this.loading = false
        });
    },

    // TODO Repeated function, move to another place
    round2decimals(num) {
      return parseFloat(num).toFixed(2);
    },
    
    getEventDate(date){
      return this.$dayjs(date).format("ddd, MMM D, YYYY h:mm A")
    },
  },
};
</script>

<style lang="css" scoped>
.forecast-number {
  font-size: 2rem;
}
</style>
